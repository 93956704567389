import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Column = makeShortcode("Column");
const Box = makeShortcode("Box");
const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "-the-global-financial-system",
      "style": {
        "position": "relative"
      }
    }}>{`💰 The Global Financial System`}<a parentName="h1" {...{
        "href": "#-the-global-financial-system",
        "aria-label": " the global financial system permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`After introducing you to the `}<a parentName="p" {...{
        "href": "/learn/module-0/play-of-pattern"
      }}>{`way we will think`}</a>{` throughout
this course and providing the example of `}<a parentName="p" {...{
        "href": "/learn/module-0/trust/"
      }}>{`trust`}</a>{` in order to demonstrate
what it means to keep complementary opposites always in mind, we then spent Week 1 exploring
`}<a parentName="p" {...{
        "href": "/learn/module-1/value/"
      }}>{`value`}</a>{` and `}<a parentName="p" {...{
        "href": "/learn/module-1/meaning/"
      }}>{`meaning`}</a>{`. These two critical
concepts will inform how we play the rest of the nascent Web 3 patterns out over the next 6 weeks. `}</p>
    <p>{`We hope that you can already appreciate a little bit more deeply how to make meaning from the
raw material of your life; not necessarily through grandiose action, but rather as the result of
joyful little subversions along the way of real awareness and humility. A life lived wide awake
naturally leads to various explorations of trust and value; the kinds of shared truth we preserve
in narrative and pass between generations. Now that we have ownerless, borderless protocols for
recording such history, we can potentially multiply the effects of thousands of little
individual subversions into a more stable, global joy.`}</p>
    <h2 {...{
      "id": "belly-laughs",
      "style": {
        "position": "relative"
      }
    }}>{`Belly Laughs`}<a parentName="h2" {...{
        "href": "#belly-laughs",
        "aria-label": "belly laughs permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`The introduction might make you think this week will continue along the idealistic and abstract
thread we have been drawing out. However, we're going to be diving deep into the guts of the
financial system, various ways to read its history, and how it actually works today. We'll even
throw in a balance sheet and a T account or two.  `}</p>
    <p>{`We'll begin with a short introduction to asking better questions, because it really is worth
asking about why systems are the way they currently are before moving straight into a disruptive
mindset. From there, we'll take an extended journey - through the links between money and
speech - into the very belly of the beast. Buckle up friends: it's gonna be a bumpy ride.`}</p>
    <h2 {...{
      "id": "week-2-firesides",
      "style": {
        "position": "relative"
      }
    }}>{`Week 2 Firesides`}<a parentName="h2" {...{
        "href": "#week-2-firesides",
        "aria-label": "week 2 firesides permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <Column sx={{
      gap: "10%"
    }} mdxType="Column">
      <Box mdxType="Box">
        <p><strong parentName="p">{`Sep Kumvar, Xochitl Cazador & Rebecca Mqamelo - Septmeber 29, 2022`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/IAcDGzf8SbA?start=343" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Annaliese Milano & Scott Moore - October 14, 2021`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/TvhEBkZsEkg?start=394" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Rebecca Mqamelo & Annaliese Milano - June 3, 2021`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/d3dieNjPuRw?start=297" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Kevin Owocki - July 23, 2020`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/1tHQ9K5Oj9s?start=380" mdxType="Video" />
      </Box>
    </Column>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      